<template>
  <div style="float:left;width:100%">
    <Refresh :qperationstatus="qperationstatus"></Refresh>
    <van-grid :column-num="3">
      <van-grid-item
        v-for="(item, index) in gridData"
        :key="index"
        :icon="item.icon"
        :text="item.name"
        :style="'color:' + item.color"
        :to="item.to"
        @click="signOut(item)"
      />
    </van-grid>
  </div>
</template>

<script>
import { Grid, GridItem } from "vant";
import Refresh from "../refresh/index.vue";
export default {
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    Refresh,
  },

  data() {
    return {
      qperationstatus:true,
      gridData: [
        {
          name: "工作记录",
          icon: "comment-o",
          color: "#27a51b",
          to: "/workRecord",
        },
        {
          name: "薪资统计",
          icon: "notes-o",
          color: "#d21a1a",
          to: "/salaryStatistics",
        },
        {
          name: "每日薪资",
          icon: "orders-o",
          color: "#d21a1a",
          to: "/dailySalary",
        },
        {
          name: "退出",
          icon: "revoke",
          color: "#27a51b",
          to: "/login",
        },
        {
          name: "",
          icon: "",
          color: "",
          to: "",
        },
        {
          name: "",
          icon: "",
          color: "",
          to: "",
        },
      ],
    };
  },

  computed: {},

  methods: {
    signOut(item) {
      if (item.name == "退出") {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
      }
    },
  },
};
</script>

<style lang="less"></style>
